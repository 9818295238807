<template>
  <b-tab>
    <template #title>
      <feather-icon
          icon="FileIcon"
          size="21"
      />
      <strong>
        Fájlok
      </strong>
    </template>

    <b-overlay
        :show="loading"
        spinner-variant="primary"
        spinner-type="grow"
        rounded="sm"
    >
      <b-row>
        <b-col cols="12" class="mb-3">
          <div class="mb-1">Csoportos fájlfeltöltéshez húzza rá, vagy kattintás után tallózza be a fájlokat a lenti
            beviteli mező segítségével.
          </div>
          <b-row>
            <b-col sm="9">
              <b-form-file
                  v-model="files"
                  placeholder="Válassz vagy húzd ide a fájlokat"
                  drop-placeholder="A fájlokat húzd ide..."
                  accept="
                  application/pdf,
                  video/x-msvideo,
                  image/bmp,
                  application/msword,
                  application/vnd.openxmlformats-officedocument.wordprocessingml.document,
                  image/gif,
                  image/jpeg,
                  video/x-matroska,
                  audio/mpeg,
                  video/mp4,
                  video/mpeg,
                  application/vnd.oasis.opendocument.spreadsheet,
                  application/vnd.oasis.opendocument.text,
                  image/png,
                  application/vnd.ms-powerpoint,
                  application/vnd.openxmlformats-officedocument.presentationml.presentation,
                  image/svg+xml,
                  application/x-tar,
                  text/plain,
                  text/csv,
                  image/webp,
                  application/vnd.ms-excel,
                  application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
                  application/zip,
                  application/x-7z-compressed
                  "
                  multiple
              />
            </b-col>
            <b-col sm="3">
              <b-button
                  v-if="files.length"
                  variant="danger"
                  class="float-left mr-1"
                  @click.prevent="() => {files = []}"
              >
                <feather-icon
                    icon="Trash2Icon"
                    size="15"
                />
              </b-button>
              <b-button
                  variant="primary"
                  type="submit"
                  :disabled="!files.length"
                  @click.prevent="submitFiles"
              >
                Csoportos feltöltés
              </b-button>
            </b-col>
          </b-row>
        </b-col>
        <!-- fájlok renderelése -->
        <draggable
            v-model="tabData"
            class="col-12 cursor-move"
            @change="onDragged"
        >
          <transition-group>
            <b-row
                class="mb-2"
                v-for="(item, index) in tabData"
                :key="item.file_id"
            >
              <b-col
                  sm="12"
              >
                <validation-observer ref="simpleRules">
                  <b-form>
                    <b-row>
                      <b-col md="6">
                        <b-form-group>
                          <validation-provider
                              #default="{ errors }"
                              name="fájlnév"
                              rules="required"
                              vid="filename"
                          >
                            <b-input-group>
                              <b-input-group-prepend>
                                <b-input-group-text>
                                  <feather-icon
                                      icon="MoveIcon"
                                      size="15"
                                  />
                                </b-input-group-text>
                              </b-input-group-prepend>
                              <b-form-input
                                  size="sm"
                                  readonly
                                  v-model="tabData[index].file.filename"
                                  :state="errors.length > 0 ? false:null"
                                  placeholder="fájlnév"
                              />
                              <b-form-input
                                  size="sm"
                                  v-model="tabData[index].file_name"
                                  :state="errors.length > 0 ? false:null"
                                  placeholder="fájlnév"
                              />
                            </b-input-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- submit button -->
                      <b-col>
                        <b-button
                            size="sm"
                            variant="warning"
                            type="submit"
                            @click.prevent="renameFile(index)"
                        >
                          <feather-icon
                              icon="EditIcon"
                              size="15"
                          />
                        </b-button>

                        <b-button
                            variant="danger"
                            size="sm"
                            class="ml-1"
                            @click.prevent="deleteFile(index)"
                        >
                          <feather-icon
                              icon="TrashIcon"
                              size="15"
                          />
                        </b-button>

                        <b-link
                            :href="item.downloadUrl"
                            target="_blank"
                        >
                          <b-button
                              variant="primary"
                              size="sm"
                              class="ml-1"
                          >
                            <feather-icon
                                icon="DownloadIcon"
                                size="15"
                            />
                          </b-button>
                        </b-link>

                      </b-col>
                    </b-row>
                  </b-form>
                </validation-observer>
              </b-col>
            </b-row>
          </transition-group>
        </draggable>
      </b-row>
    </b-overlay>
  </b-tab>
</template>

<script>
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {
  BTab,
  BBadge,
  BRow,
  BCol,
  BButton,
  BFormFile,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupText,
  BOverlay,
  BForm,
  BLink,
} from 'bootstrap-vue'

import draggable from 'vuedraggable'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BTab,
    BBadge,
    BRow,
    BCol,
    BButton,
    BFormFile,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupText,
    BOverlay,
    BForm,
    BLink,
    draggable
  },
  props: {
    data: [],
    entityId: {
      type: Number,
      required: true
    },
    entityType: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      dangerCnt: 0,
      tabData: [],
      files: [],
      tmp: {
        id: null,
        name: null,
        file: null,
      },
    }
  },
  watch: {
    data: {
      handler(value) {
        this.tabData = value
        this.initTab()
      },
      deep: true
    }
  },
  created() {
    this.initTab()
  },
  methods: {
    initTab() {
      this.$store.dispatch('getFilesForEntity', {
        id: this.$route.params.id,
        type: this.entityType
      }).then(async response => {
        let tmp = []

        Object.keys(response.data.files).forEach(key => {
          tmp.push(response.data.files[key])
        })

        this.tabData = tmp

      }).catch(error => {
        if (error.response.status === 422) {
          this.$refs.simpleRules.setErrors(error.response.data.errors)
        } else {
          this.$helpers.showErrorToast(error.toString())
        }
      })
    },
    onDragged() {
      this.showLoadBar()
      let newOrder = []

      Object.keys(this.tabData).forEach(key => {
        newOrder.push(this.tabData[key].file_id)
      })
      this.$store.dispatch('orderFilesForEntity', {
        id: this.entityId,
        type: this.entityType,
        order: newOrder
      }).then(() => {
        this.$helpers.showSuccessToast()
      }).catch(error => {
        if (error.response.status === 500) {
          this.$helpers.showErrorToast(error.response.data.message)
        } else {
          this.$helpers.showErrorToast(error.toString())
        }
      }).finally(() => {
        this.hideLoadBar()
      })
    },
    submitFiles() {
      if (this.files.length) {
        this.showLoadBar()
        let all = 0
        let success = 0
        Object.keys(this.files).forEach(key => {
          const fd = new FormData()
          fd.append('customFileName', this.files[key].name)
          fd.append('modelId', this.$route.params.id)
          fd.append('modelType', this.entityType)
          fd.append('file', this.files[key])

          this.$store.dispatch('storeModelFile', fd).then(() => {
            success += 1
          }).catch(error => {
            if(error.response.status === 413){
              this.$helpers.showErrorToast('A feltölteni kívánt fájl(ok) túl nagy(ok).')
            }else if(error.response.status === 400){
              this.$helpers.showErrorToast(error.response.data.message)
            }else{
              this.$helpers.showErrorToast(error.toString())
            }
            this.hideLoadBar()
          }).finally(() => {
            all += 1
            if (success === this.files.length) {
              this.files = []
              this.$emit('refresh-data')
              this.$helpers.showSuccessToast()
              this.hideLoadBar()
            }

            if (all === this.images.length) {
              this.hideLoadBar()
            }
          })
        })
      }
    },
    closeModal(modalName) {
      this.$bvModal.hide(modalName)
      this.resetTempData()
    },
    resetTempData() {
      this.tmp = {
        id: null,
        name: null,
        file: null,
      }
    },
    renameFile(index) {
      this.showLoadBar()

      const fd = new FormData()
      fd.append('modelId', this.$route.params.id)
      fd.append('modelType', this.entityType)
      fd.append('fileId', this.tabData[index].file_id)
      fd.append('filename', this.tabData[index].file_name)

      // törlés
      this.$store.dispatch('renameFile', fd)
          .then(() => {
            this.tabData[index].file = null
            this.$emit('refresh-data')
            this.$helpers.showSuccessToast()
          }).catch(error => {
        if (error.response.status === 422) {
          this.$helpers.showErrorToast(error.response.data.errors[0])
        } else {
          this.$helpers.showErrorToast(error.toString())
        }
      }).finally(() => {
        this.hideLoadBar()
      })
    },
    deleteFile(index) {
      this.$bvModal
          .msgBoxConfirm('Biztosan törölni szeretnéd az állományt?', {
            title: 'Jóváhagyás',
            size: 'sm',
            okVariant: 'primary',
            okTitle: 'Igen',
            cancelTitle: 'Nem',
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          })
          .then(value => {
            if (value) {
              this.showLoadBar()

              const fd = new FormData()
              fd.append('modelId', this.$route.params.id)
              fd.append('modelType', this.entityType)
              fd.append('fileId', this.tabData[index].file_id)

              // törlés
              this.$store.dispatch('deleteFile', fd)
                  .then(() => {
                    this.tabData[index].file = null
                    this.$emit('refresh-data')
                    this.$helpers.showSuccessToast()
                  }).catch(error => {
                if (error.response.status === 422) {
                  this.$helpers.showErrorToast(error.response.data.errors[0])
                } else {
                  this.$helpers.showErrorToast(error.toString())
                }
              }).finally(() => {
                this.hideLoadBar()
              })
            }
          })
    },
    showLoadBar() {
      this.loading = true
    },
    hideLoadBar() {
      this.loading = false
    },
  }
}
</script>

<style scoped>

</style>
