<template>
  <div>
    <b-tooltip :target="createNewsletterButton">Hírlevél létrehozása</b-tooltip>
    <b-button
        v-if="$acl.canCreatePermission('newsletter')"
        id="createNewsletterButton"
        :size="smallButton ? 'sm' : ''"
        :variant="variant"
        @click.stop="createDraft"
    >
      <feather-icon
          icon="MailIcon"
      />
      <span
          v-if="!smallButton"
      >
      &nbsp;&nbsp;Hírlevél készítése
    </span>
    </b-button>
  </div>
</template>

<script>
import {
  BButton, BTooltip,
} from 'bootstrap-vue'

export default {
  components: {
    BButton,
    BTooltip,
  },
  props: {
    entityId: {
      type: Number,
      required: true,
    },
    entityType: {
      type: String,
      required: true,
    },
    smallButton: {
      type: Boolean,
      required: false,
      default: false,
    },
    variant: {
      type: String,
      required: false,
      default: 'primary',
    }
  },
  methods: {
      createDraft() {
        this.$bvModal
            .msgBoxConfirm('Biztos létrehozol az adott elemből egy hírlevél példányt?', {
              title: 'Jóváhagyás',
              size: 'sm',
              okVariant: 'primary',
              okTitle: 'Igen',
              cancelTitle: 'Nem',
              cancelVariant: 'outline-secondary',
              hideHeaderClose: false,
              centered: true,
            })
            .then(value => {
              if (value) {
                // axios
                this.$store.dispatch('storeNewsletterDraft', { entityId: this.entityId, entityType: this.entityType }).then(response => {
                  this.$helpers.showSuccessToast(`Sikeres létrehozás (${response.data.newsletterId})`)
                  this.$router.push({name: 'modify-newsletter', params: {id: response.data.newsletterId}})
                }).catch(() => {
                  this.$helpers.showErrorToast('Sikertelen létrehozás! Próbáld újra, vagy keresd fel az üzemeltetőt!')
                })
              }
            })
      }
  },
}
</script>

<style scoped>

</style>
